import React from "react"
import Lottie from "react-lottie"
import styled from "styled-components"

import Header from "../components/header"
import GlobalFonts from "../font/fonts"

import icon1 from "../images/gatsby-astronaut.png"
import icon2 from "../assets/ICON2.svg"
import icon3 from "../assets/ICON3.svg"
import icon4 from "../assets/ICON4.svg"

import topbackground from "../assets/TopPolygon1.svg"
import topbackground2 from "../assets/TopPolygon2.svg"

import ResponsiveAppBar from "../components/ResponsiveAppBar"
import FooterContainer from "../components/FooterContainer"

import home7 from "../images/home-7.jpeg"
import testi from "../images/testi.jpeg"
import beachland from "../images/beach-landscape.jpg"
import iconItem from "../images/Icon.png"
import pool_after_img from '../images/pool_after_img.png'

const Resources = () => {

  const handleOpenBlog = () => {
    window.location.href = '/blog'
  }

    const handleOpenTesti = () => {
   window.location.href = '/testimonials' 
  }

  const handleOpenVideos = () => {
   window.location.href = '/videos' 
  }

  const handleOpenWebinar = () =>{
    window.location.href = '/webinar'
  }
  const handleOpenCaseStudies  = () =>{
    window.location.href = '/our-mission'
  }

  const handlepropSpot  = () =>{
    window.location.href = '/property-spotlight'
  }

  return (
    <>
    <GlobalFonts />
    <ResponsiveAppBar />
    {/*<Background>    
      <TopBackground src={topbackground} />
    </Background>*/}

  	<Top>
    
        <HowItWorks>RESOURCES</HowItWorks>
        <HowItWorks2>RESOURCES</HowItWorks2>
        <H1Text>Learn About Our Mission To Enable Everyone To Own Real Estate</H1Text>
        </Top>
    <Body>

{/*        <Step1Div onClick={handleOpenBlog}>
          <Icon1 alt="search" src={icon1} />
          <HowText1>Blog</HowText1>
          <HowSubText1>
            Learn about cutting edge ideas in the real estate space.
          </HowSubText1>
        </Step1Div>
*/}
        <Step1Div onClick={handleOpenTesti}>
          <Icon1 alt="hear-from-real-users" src={testi} />
          <HowText1>Hear From Real Users</HowText1>
          <HowSubText1>
            See what other people are saying about Cloud Castles
          </HowSubText1>
        </Step1Div>

        <Step1Div onClick={handleOpenVideos}>
          <Icon1 alt="renovations" src={home7} />
          <HowText1>Start To Finish</HowText1>
          <HowSubText1>
            See our renovations, properties and what Cloud Castles can do for your portfolio.
          </HowSubText1>
        </Step1Div>
        <Step1Div onClick={handleOpenWebinar}>
          <Icon1 alt="search" src={beachland} />

          <HowText1>Webinar</HowText1>
          <HowSubText1>
            Watch a recorded Podcast to see what our business stands for.
          </HowSubText1>
        </Step1Div>

        <Step1Div onClick={handleOpenCaseStudies}>
          <Icon1 alt="Mission" src={iconItem} />

          <HowText1>Our Mission</HowText1>
          <HowSubText1>
            Hear from real people who have worked with Cloud Castles.
          </HowSubText1>
        </Step1Div>
        
        <Step1Div onClick={handlepropSpot}>
          <Icon1 alt="Property Spotlight" src={pool_after_img} />

          <HowText1>Property Spotlight</HowText1>
          <HowSubText1>
            See the numbers for a real property.
          </HowSubText1>
        </Step1Div>

        </Body>
        <FooterContainer />
    </>
  )
}

export default Resources

const Background = styled.div`
  width:100%;
  overflow-x: hidden;
  overflow: hidden;
`


const Body = styled.div`
	margin: 80px 0px 100px 40px;
  position:relative;  
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 40px;
  justify-content: flex-start;
	

	@media (max-width: 1332px) {
    // margin: 50px 0px 100px 50px;

		grid-template-columns: repeat(2, 1fr);
	}
	@media (max-width: 767px) {
    // margin: 50px 0px 100px 50px;
		grid-template-columns: repeat(1, 1fr);
	}

    @media (max-width: 500px) {
    // margin: 50px 0px 50px;
    margin-left:15px;
    grid-template-columns: repeat(1, 1fr);
  }
`

const Top = styled.div`

  height:200px;
	@media (max-width: 1332px) {
		
	}
	@media (max-width: 769px) {
		
	}
`


const HowItWorks = styled.h2`
  position: relative;
  width: 616px;
  height: 99px;
  left: 100.12px;
  top: 100.33px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 65.7269px;
  line-height: 99px;
  /* identical to box height */

  letter-spacing: 1.56101px;
  text-transform: uppercase;

  color: #000000;

  opacity: 0.04;

  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
    left: 60.12px;

  }
`

const HowItWorks2 = styled.h2`
  position: relative;
  width: 358px;
  height: 60px;
  left: 100.12px;
  top: 50.63px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 36.9714px;
  line-height: 55px;
  letter-spacing: 1.56101px;
  text-transform: uppercase;

  color: #000000;
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
    left: 60.12px;
  }
`

const Step1Div = styled.div`
	max-width: 330px;
	height: 500px;
  margin-left:30px;
  // margin-right:10px;
	position: relative;
	display: grid;
	grid-template-rows: 1fr 1fr;
  background: rgba(255, 255, 255, .7);
  border-radius: 33px;

  alignItems: stretch;
  top: 0px;
	transition:0.8s cubic-bezier(0.2,0.8,0.2,1);
  transition: all 0.15s ease-in-out;
  box-shadow: 0px 3.58802px 22.4251px rgba(0.55,0.75, 0.95, 0.26);
	&:hover {
		// transition:0.8s cubic-bezier(0.2,0.8,0.2,1);
      top: -15px;
      cursor: pointer;
      background: white;
      box-shadow: 0px ;

	}

    @media (max-width: 1332px) {
    // margin: 50px 0px 100px 50px;
    max-width: 80%;
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 767px) {
    // margin: 50px 0px 100px 50px;
    grid-template-columns: repeat(1, 1fr);
  }

    @media (max-width: 300px) {
    // margin: 50px 0px 50px;
    
    grid-template-columns: repeat(1, 1fr);
  }

`



const Icon1 = styled.img`
  position: absolute;
   object-fit: cover;
  width: 100%;
  height: 300px;
  border-radius: 33px 33px 0px 0px
  
  // top: 0.39px;


`
const HowText1 = styled.h2`
  position: relative;
  
  height: 34px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top:310px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  font-size: 24.6476px;
  line-height: 33px;

  /* or 134% */
  color: #1d293f;
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

const HowSubText1 = styled.p`
  position: absolute;
  margin-left: 15px;
  margin-right: 15px;
  margin-top:380px;
  // top: 68.65%;
  // bottom: 8.65%;

  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16.4317px;
  line-height: 23px;
  /* or 138% */

  color: #737b7b;
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  	
  }
`

const Icon2 = styled.img`
  position: absolute;
  width: 94.29px;
  height: 94.29px;
  left: 53.31px;
  top: 21.39px;
    @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

const TopBackground = styled.img`

  position: absolute;
  left: -34.63%;
  right: -49.75%;
  top: -12.3%;
  // bottom: 78.14%;
  zPosition:-100;
  overflow-x: hidden;
  background: #8bbef3;
  border-radius: 333px;
  
  transform: matrix(0.98, -0.24, 0.2, 0.97, 0, 0);
`
const H1Text = styled.h1`
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 15.8802px;
  line-height: 119%;
  /* or 43px */

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  font-feature-settings: "liga" off;

  margin: 40px;
  margin-left: 80px;
  margin-top: 80px;
  padding: auto;

  color: #ffffff;
  @media only screen and (max-width: 600px) and (min-width: 100px) {
  }
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`
